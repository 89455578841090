import React, { FC } from "react";
import styled from "styled-components";

const SideBar = styled.div`
  position: absolute;
  border: 1px solid #fff;
`;

export interface AgendaItem {
  time: string;
  title: string;
  speakers: Record<string,string>[]
}

const AgendaData: AgendaItem[] = [
  { time: "now", title: "now", speakers: [{}, {}] },
  { time: "now", title: "now", speakers: [{}, {}] },
  { time: "now", title: "now", speakers: [{}, {}] },
  { time: "now", title: "now", speakers: [{}, {}] },
  { time: "now", title: "now", speakers: [{}, {}] },
];

const AgendaSidebar = () => {
  return (
    <>
      <SideBar>
        <h1>Agenda</h1>

        <div>Time, Title, Speakers</div>
        <AgendaSidebarItem items={AgendaData} />
      </SideBar>
    </>
  );
};

export interface AgendaSidebarItemProps {
  items: AgendaItem[]
}
const AgendaSidebarItem:FC<AgendaSidebarItemProps> = ({ items }) => {
  return (
    <>
      {items.map((item, index:number) => {
        return <div key={index}>{item.title}</div>;
      })}
    </>
  );
};
export default AgendaSidebar;
